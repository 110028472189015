<template>
  <div class="about g-content">
    <div class="u-title"><span class="u-title-txt">Overview</span></div>
    <div class="about-info">
      <div class="ai-t" v-if="exInfo">
        <img :src="exInfo.logo" v-load="'oPic'" />
        {{ exInfo.nameEn || exInfo.name }}
      </div>
      <div class="u-empty" v-if="loading">{{ $t("lang.loading") }}</div>
      <div class="u-empty" v-if="!loading && !exInfo && !exInfo.logo && !exInfo.nameEn && !exInfo.name">{{ $t("lang.No_data") }}</div>
      <div class="ai-b">
        <div class="comm_text" v-if="remark&&remark.length">
          <div v-for="(item, index) in remark" :key="index">
            <!-- 文本 -->
            <p
              class="int-text"
              v-if="item.text"
              :style="{
                'font-size': item.text.fontSize,
                color: item.text.color,
                'text-align': item.text.position,
              }"
              v-html="$service.replaceSpeace(item.text.content)"
            ></p>

            <!-- 图片 -->
            <div
              class="int-img"
              v-if="item.picture && item.picture.type == 1"
              :style="{
                'padding-left': item.picture.padding + 'px',
                'padding-right': item.picture.padding + 'px',
              }"
            >
              <div v-for="(itm, idx) in item.picture.list" :key="idx" :style="{ 'margin-bottom': item.picture.spacing + 'px' }">
                <img :src="itm.url" style="width: 100%; display: block" />
              </div>
            </div>

            <div  v-if="item.picture && item.picture.type == 2 && item.picture.list && item.picture.list.length">
              <Carousel loop autoplay :radius-dot="true" :autoplay-speed="5000">
                <CarouselItem v-for="(item, index) in item.picture.list" :key="'pic' + index">
                  <img :src="item.url" style="width: 100%;height: 100%;" />
                </CarouselItem>
              </Carousel>
            </div>

            <!-- 视频 -->
            <div class="int-video" style="position: relative;margin-top: 20px;" v-if="item.video">
              <videoPlayer :url="item.video.link" :cover="item.video.cover" :time="item.video.Time"></videoPlayer>
            </div>
          </div>
        </div>
        <!-- {{ exInfo.remarkEn }} -->
      </div>
    </div>
    <div class="u-title" v-if="exInfo.website"><span class="u-title-txt">Website</span></div>
    <div class="about-brand" v-if="exInfo.website">
      <a :href="exInfo.website" target="_blank">{{ exInfo.website }}</a>
    </div>
    <!-- <template v-if="!loading && !OrganList.length"> -->
    <template v-if="!loading">
      <div class="u-title" v-if="OrganList"><span class="u-title-txt">Organization</span></div>
      <div class="about-list" v-for="(item, index) in OrganList" :key="index">
        <div class="al-l">{{ getTitle(item.title) }}：</div>
        <div class="al-r">
          <span v-for="(cItem, index) in item.conList" :key="index">{{ cItem }}</span>
        </div>
      </div>
      <div class="u-empty" v-if="loading">{{ $t("lang.loading") }}</div>
      <div class="u-empty" v-if="!loading && !OrganList.length">{{ $t("lang.No_data") }}</div>
    </template>
    <!-- <div class="u-title" v-if="exInfo.website"><span class="u-title-txt">Moments</span></div>  -->
    <more-video-line></more-video-line>
    <!-- 展会日程 20210709 -->
    <template v-if="dateList && dateList.length">
      <div class="u-title"><span class="u-title-txt">Schedule</span></div>
      <div class="schedule-wrap">
        <div class="sche_content">
          <div class="steps_bar" :style="'bottom: 20px'"></div>
          <template v-for="(item, index) in dateList">
            <div class="sche_item" :key="'sche-1-' + index">
              <div class="step">{{ index + 1 }}</div>
              <div class="info" style="width: 80%">
                <p class="date">{{ item[0].startAt | moment("YYYY/MM/DD") }}</p>
              </div>
            </div>
            <div class="sche_item2" v-for="(it, idx) in item" :key="'sche-child-' + index + idx">
              <div class="step"></div>
              <div class="info" style="width: 80%">
                <p class="date">
                  {{ it.startAt | moment("MM/DD HH:mm") }} ~ {{ it.endAt | moment("HH:mm") }}
                  <span class="schedule_tag red" id="waa" v-if="it.state == 1">ing</span>
                  <span class="schedule_tag" v-if="it.state == 2">end</span>
                </p>
                <p class="sche_type" :class="it.state == 1 ? 'ing' : ''">
                  {{ it.themeEn }}
                </p>
              </div>
            </div>
          </template>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moreVideoLine from "@components/more-video-line.vue";
import videoPlayer from "@components/videoPlayer/videoPlayer.vue";
export default {
  name: "about",
  data() {
    return {
      OrganList: [], //Organization
      exInfo: "",
      remark:[],
      loading: true,
      dateList: [], // 展会日程
    };
  },
  components: { moreVideoLine ,videoPlayer},
  computed: {
    ...mapGetters({
      exhibitionInfo: "getExhibitionInfo",
      exhibitionId: "getExhibitionId",
    }),
  },
  created() {
    this.setShare();
    this.getExhibition();
    this.getDate();
    document.title = this.exhibitionInfo ? this.exhibitionInfo.nameEn : "ExShow";
  },
  methods: {
    ...mapActions({
      graphqlPost: "graphqlPostByZXS",
      getShareConfig: "share/getShareConfig",
    }),
    async setShare() {
      let data = await this.getShareConfig({ id: this.exhibitionId, name: "share.exhibition.config" });
      let share = {
        url: location.href,
        title: "关于展会-" + this.exhibitionInfo.name,
        desc: "",
        summary: this.exhibitionInfo.remark,
        pics: this.exhibitionInfo.logo,
      };
      if (data) {
        let dataJson = JSON.parse(data);
        if (dataJson.contentEn) {
          share.title = dataJson.contentEn;
        }
        if (dataJson.des) {
          share.desc = dataJson.des;
          share.summary = dataJson.des;
        }
      }
      this.$store.commit("setShare", share);
    },
    getTitle(title) {
      let str = "";
      switch (title) {
        case "主办单位":
          str = "Host";
          break;
        case "承办单位":
          str = "Organizer";
          break;
        case "协办单位":
          str = "Co-organizer";
          break;
        case "执行单位":
          str = "Executive unit";
          break;
        case "支持单位":
          str = "Support unit";
          break;
        case "承办单位":
          str = "Organizer";
          break;
        case "指导单位":
          str = "Guidance unit";
          break;
        case "合作单位":
          str = "Cooperation unit";
          break;
        case "批准单位":
          str = "Audit unit";
          break;
        case "友好单位":
          str = "Partner unit";
          break;
        case "媒体合作单位":
          str = "Media unit";
          break;
        case "组织单位":
          str = "Organizer";
          break;
        case "其他":
          str = "Other";
          break;
        default:
          str = title;
          break;
      }
      return str;
    },
    async getExhibition() {
      let query = `
        query($id:String!){
          exhibitionQuery{
           get(id:$id){
              organization
              organizationEn
              remark
              remarkEn
              logo
              name
              nameEn
              website
            }
          }
        }
      `;
      let opt = {
        query: query,
        variables: {
          id: this.exhibitionId,
        },
      };
      let res = await this.graphqlPost(opt);
      if (res.data.exhibitionQuery.get) {
        this.exInfo = res.data.exhibitionQuery.get;
        if (this.exInfo.organization) {
          var organ = this.exInfo.organization;
          let arr = [];
          if (organ && organ.indexOf("[") != -1) {
            arr = JSON.parse(organ);
            arr.forEach((e) => {
              e.conList = e.contentEn.split("\n");
            });
          }
          this.OrganList = arr;
          console.log(this.OrganList, "1");
          if (this.exInfo.remarkEn && this.exInfo.remarkEn.indexOf("[") !== -1) {
          this.remark = JSON.parse(this.exInfo.remarkEn);
        } else if (this.exInfo.remarkEn) {
          let arr = [{ text: { type: 1, content: this.exInfo.remarkEn, fontSize: "14px", color: "#000", position: "left" } }];
          this.remark = arr;
        }
        }
      }
      this.loading = false;
    },
    // 获取展会日程
    async getDate() {
      let query = `
        query($where:String!){
          exhibitionScheduleQuery{
           getList(where:$where){
              exhibitionId
              date
              startTime
              endTime
              theme
              themeEn
              speaker
              advert
              address
              organizer
              id
              isDeleted
              createAt
              updateAt
            }
          }
        }
      `;
      let where = {
        Equal: {
          n: "exhibitionId",
          v: this.exhibitionId,
        },
      };
      let opt = {
        query: query,
        variables: {
          where: JSON.stringify(where),
        },
      };
      let res = await this.graphqlPost(opt);
      if (res.data.exhibitionScheduleQuery) {
        let List = JSON.parse(JSON.stringify(res.data.exhibitionScheduleQuery.getList));
        var obj = {}, newArr = [];
        List.sort(function(a, b) {
          return Date.parse(b.startTime.replace(/-/g, "/")) - Date.parse(a.startTime.replace(/-/g, "/"));
        });
        List.forEach((item) => {
          let startAt = item.date.slice(0, 11) + item.startTime.slice(11, 19);
          item.startAt = startAt.replace(/-/g, "/");
          let endAt = item.date.slice(0, 11) + item.endTime.slice(11, 19);
          item.endAt = endAt.replace(/-/g, "/");
          item.state = this.compareDate(item.startAt, item.endAt);
          if(item.themeEn) {
            if (!obj[item.startAt.substring(0, 11)]) {
              var arr = [];
              arr.push(item);
              newArr.push(arr);
              obj[item.startAt.substring(0, 11)] = item;
            } else {
              newArr.forEach((value) => {
                if (value[0].startAt.substring(0, 11) == item.startAt.substring(0, 11)) {
                  value.push(item);
                }
              });
            }
          }
        });
        newArr.forEach((e) => {
          e.sort((a, b) => {
            return Date.parse(a.startTime.replace(/-/g, "/")) - Date.parse(b.startTime.replace(/-/g, "/"));
          });
        });
        this.dateList = newArr.reverse();
        console.log("展会日程", this.dateList);
      }
    },
    // 比较时间
    compareDate(statedate, enddate) {
      const stateTime = new Date(statedate).getTime();
      const Time = new Date().getTime();
      const endTime = new Date(enddate).getTime();
      if (stateTime < Time && Time < endTime) {
        return 1; //进行中
      } else if (endTime < Time) {
        return 2; //已结束
      } else {
        return 3; //未开始
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@assets/scss/my-theme/mixin.scss";
.about {
  margin-top: 20px;
  margin-bottom: 87px;
  background-color: #fff;
  border: solid 1px #ededed;
  padding: 12px 30px 70px;
  &-list {
    display: flex;
    margin-bottom: 38px;
    .al {
      &-l {
        width: 120px;
        font-size: 16px;
        font-weight: bold;
        color: #333;
        text-align: right;
      }
      &-r {
        flex: 1;
        font-size: 14px;
        color: #333;
        span {
          display: block;
        }
      }
    }
  }
  &-brand {
    background-color: #f7f7f7;
    border-radius: 3px;
    padding: 10px;
    font-size: 14px;
    color: #333;
    margin-bottom: 20px;
    background-color: #f7f7f7;
  }
  &-info {
    .ai-t {
      img {
        width: 60px;
        height: 60px;
        background-color: #fff;
        border: solid 1px #ededed;
        display: inline-block;
        margin-right: 15px;
      }
      display: flex;
      align-items: center;
      font-size: 16px;
      color: #1a1a1a;
    }
    // .ai-b {
    //   margin-top: 20px;
    //   margin-bottom: 20px;
    //   font-size: 14px;
    //   color: #333;
    // }
  }
  .comm_text {
    background: #fff;
    color: #333;
    // padding: 35px 0;
    // margin-bottom: 20px;
    > div {
      &:last-child {
        .int-video,
        .int-img {
          margin-bottom: 20px;
        }
      }
    }
    > span {
      line-height: 1;
      padding: 0 0 20px 30;
      font-size: 32px;
      display: inline-block;
    }
    p {
      line-height: 1.6;
      font-size: 14px;
    }
    img {
      display: block;
    }
    .int-text {
      padding: 30px 0;
    }
  }
}

.schedule-wrap {
  .sche_content {
    padding: 0 10px;
    position: relative;
    .steps_bar {
      width: 3px;
      position: absolute;
      left: 23px;
      z-index: 0;
      top: 25px;
      bottom: 0;
      background: #ff4550;
    }
    .sche_item {
      display: flex;
      position: relative;
      z-index: 1;
      &.end {
        .sche_type {
          color: #999;
        }
      }
      .step {
        margin-right: 10px;
        width: 30px;
        height: 30px;
        line-height: 30px;
        border-radius: 50%;
        background: linear-gradient(to left, #ff416c, #ff4b2b);
        color: #fff;
        font-size: 14px;
        text-align: center;
      }
      .info {
        font-size: 15px;
        color: #ff5c00;
        display: flex;
        align-items: center;
      }
    }
    .sche_item2 {
      display: flex;
      margin-top: 10px;
      position: relative;
      z-index: 1;
      &.end {
        .sche_type {
          color: #999;
        }
      }
      .step {
        margin: 0 22px 0 8px;
        width: 12px;
        height: 12px;
        line-height: 12px;
        border-radius: 50%;
        background: linear-gradient(to left, #ff416c, #ff4b2b);
        color: #fff;
        font-size: 14px;
        text-align: center;
      }
      .date {
        line-height: 14px;
        height: 14px;
        font-size: 14px;
        margin-bottom: 6px;
        font-size: 12px;
        color: #999;
      }
      .sche_type {
        line-height: 1.5;
        margin-bottom: 14px;
        font-size: 12px;
        color: #999;
        span {
          color: #666;
          margin-left: 15px;
        }
      }
      .ing {
        color: #333;
      }
    }
    .schedule_tag {
      font-size: 12px;
      vertical-align: middle;
      padding: 2px 6px;
      border-radius: 3px;
      display: inline-block;
      background: #e6e6e6;
      color: #666;
      margin-left: 10px;
    }
    .red {
      background-color: #ff4550;
      color: #fff;
    }
  }
}
</style>
